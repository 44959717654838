<template>
  <div class="product-wrap product-ggd">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-ggd"></div>
    <div class="white-wrap">
      <div class="max-center">
        <el-row gutter="50" type="flex" justify="center">
          <el-col :span="8">
            <div class="pdg-box">
              <div class="img">
                <img src="../../assets/img/product/ggd/pic_1.png" alt="低压配电柜">
              </div>
              <div class="txt">低压配电柜</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="pdg-box">
              <div class="img"><img src="../../assets/img/product/ggd/pic_2.png" alt="高压配电柜"></div>
              <div class="txt">高压配电柜</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="fz-black align-lt">低压配电柜</div>
        <el-row :gutter="30">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="auto-img">
              <img src="../../assets/img/product/ggd/pic_1.png" alt="低压配电柜">
            </div>
          </el-col>
          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="txt-box">
              GCS型低压抽出式开关柜，适用于水厂、石油、化工、冶金、纺织、高层建筑等行业的配电系统。在大型水厂、石化系统等自动化程度高，要求与计算机接口的场所，作为三相交流频率为50(60)Hz、额定工作电压为400V、660V,额定电流为5000A及以下的发、供电系统中的配电、电动机集中控制、无功功率补偿使用的低压成套配电装置。装置的设计符合下列标准：439-1《低压成套开关和控制设备》GB7251《低压成套开关设备》。
            </div>
          </el-col>
        </el-row>
        <div class="name-title">
          <i class="i-lt"></i><i class="txt">型号含义</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/ggd/pic_3.png" style="margin-left: 5%" alt="型号含义">
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">正常使用条件</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="txt-box condition-box pd-5">
          <p><span>♦</span>周围空气温度不高于+40℃,不低于-5℃, 24小时内平均温度不得高于+35℃。超过时，需根据实际情况降容运行；</p>
          <p><span>♦</span>户内使用，使用地点的海拔高度不得超过2000m；</p>
          <p><span>♦</span>周围空气相对湿度在较高温度为+40℃：时不超过50%,在较低温度时允许有较大的相对湿度，如+20℃时为90%,应考虑到由于温度的变化可能会偶然产生凝露的影响；</p>
          <p><span>♦</span>装罝安装时与垂直面的倾斜度不超过5°,且整组柜列相对平整（符合GBJ232-82标准)；</p>
          <p><span>♦</span>装皆应安装在无剧烈琪动和冲击以及不足以使电器元件受到不应有腐蚀的场所；</p>
          <p><span>♦</span>用户有特别要求时，可以与制造厂协商解决。</p>
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">主要技术参数</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/ggd/pic_4.png" style="margin-left: 5%" alt="主要技术参数">
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">外形及安装尺寸</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/ggd/pic_5.png" style="margin-left: 5%" alt="外形及安装尺寸">
          <p style="margin-left: 5%" class="mt-2">单位：mm</p>
          <img src="../../assets/img/product/ggd/pic_6.png" style="margin-left: 5%" alt="外形及安装尺寸">
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-black align-lt">高压配电柜</div>
        <el-row :gutter="30">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="auto-img">
              <img src="../../assets/img/product/ggd/pic_2.png" alt="高压配电柜">
            </div>
          </el-col>
          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="txt-box">
              <p>XGN66-12型固定式封闭开关设备(以下简称开关柜)是我公司高压电器成套产品，符合国家标准GB3906《3-35kV交流金属
                封闭开关设备》电力部DLT404《户内交流高压开关柜订货技术条件》的要求，也满足标准IEC60298《1kV以上52kV以下交流金厲封闭开关设备和控制设备的要求》。</p>
              <p>该产品它体积小，仅是普通开关柜体积的一半；断路器具有可靠性高，性能好；“五防”联锁机构可靠、
                简单等优点。开关柜是3.6、7.2、12kV三相交流电50Hz单母线分段的户内成套装罝，作为接收和分配电能之用。并具有对电路进行控制、保护和监测等功能，可使用在各类型发电厂、变电站及工矿企业，高层建筑等场所，也可与环网柜组合应用于开闭所中。</p>
            </div>
          </el-col>
        </el-row>
        <div class="name-title">
          <i class="i-lt"></i><i class="txt">型号含义</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/ggd/pic_7.png" style="margin-left: 5%" alt="型号含义">
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">正常使用条件</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="txt-box condition-box">
          <p><span>♦</span>海拔高度不超过1000m。</p>
          <p><span>♦</span>环境温度：-25℃~+40℃，24小时内平均温度不超过+35℃。</p>
          <p><span>♦</span>水平倾斜度不大于3°。</p>
          <p><span>♦</span>地震烈度不超过8度。</p>
          <p><span>♦</span>无剧烈振动和冲击及爆炸危险场所。</p>
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">主要技术参数</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/ggd/pic_8.png" style="margin-left: 5%" alt="主要技术参数">
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">外形及安装尺寸</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/ggd/pic_9.png" style="margin-left: 5%" alt="外形及安装尺寸">
        </div>
      </div>
    </div>

    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="auto-img" style="position: relative">
          <img src="../../assets/img/product/instrument/pic_9.jpg" alt="更多配电柜请联系我们！">
          <span class="more-txt" @click="handleShow">更多配电柜请联系我们！</span>
        </div>
      </div>
    </div>

    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '高压配电柜-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控高压配电柜-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-ggd {
  .banner-ggd {
    background: url("../../assets/img/product/ggd/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }

  .pdg-box {
    width: 100%;
    height: auto;
    position: relative;
    border: 2px solid #D9D9D9;

    .img {
      width: 90%;
      margin: 0 auto;
      height: auto;
      padding: 15% 0 25%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .txt {
      background: #F0F0F0;
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #595959;
      position: absolute;
      bottom: 0
    }
  }

  .pdg-box:hover {
    border: 2px solid #1890FF;

    .img {
      transform: scale(1.1);
    }

    .txt {
      background: #1890FF;
      color: #FFFFFF;
    }
  }

  .condition-box {
    span {
      color: #1890FF;
      padding: 0 2px;
    }
  }

  .sm-txt {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
  }

  .big-txt {
    font-size: 48px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
    line-height: 1.8;
  }

  .more-txt {
    padding: 2% 5%;
    background: #1890FF;
    color: #ffffff;
    position: absolute;
    right: 15%;
    top: 25%;
  }

}

@media (max-width: 749px) {
  .product-ggd {
    .pdg-box {
      .img {
        padding: 10% 0 25%;
      }
      .txt {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }
    }
  }
}
</style>
